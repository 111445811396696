<template>
    <div class="p-4 rounded-lg">
        <div class="pb-5 border-b border-gray-200">
            <h3 class="text-2xl leading-6 font-medium text-white">
                <slot name="sectionTitle">
                    Section Title
                </slot>
            </h3>
        </div>

        <div class="relative flex content-center items-center">
            <transition enter-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-active-class="transition ease-out duration-300"
                        enter-to-class="transform opacity-100 translate-y-0 sm:scale-100"
                        leave-class="transform opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="transition ease-in duration-200"
                        leave-to-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

                <button @click="rightArrowPressed" v-if="showRightArrow && (myGroupSessions.participating !== null)"
                        class="text-gray-200 p-1 absolute right-0 -mr-6 z-50 hover:bg-purple-500 bg-jiruto-zotSecondary rounded-full hover:text-white focus:bg-purple-600 active:bg-purple-600">
                    <svg class="w-10 h-10 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </button>
            </transition>

            <transition enter-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-active-class="transition ease-out duration-300"
                        enter-to-class="transform opacity-100 translate-y-0 sm:scale-100"
                        leave-class="transform opacity-100 translate-y-0 sm:scale-100"
                        leave-active-class="transition ease-in duration-200"
                        leave-to-class="transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">

                <button @click="leftArrowPressed" v-if="showLeftArrow" class="text-gray-200 p-1 absolute left-0 -ml-6 z-50 hover:bg-purple-500 bg-jiruto-zotSecondary rounded-full hover:text-white">
                    <svg class="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
                </button>
            </transition>

            <div @scroll="scrolled" :id="scrollerID" class="scroller relative mt-12 flex overflow-x-scroll scrolling-touch gap-2 mx-auto w-full">

                <group-session-card v-for="(session) in myGroupSessions"
                                    :key="session.groupSession.JID" @click="openSession" :session="session"></group-session-card>
            </div>
        </div>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import GroupSessionCard from "@/components/GroupSessionCard";
    export default {
        name: "GroupSessionsScroller",
        components: {GroupSessionCard},
        props: {
            myGroupSessions: {
                participating: null,
                invited: null,
            },
        },
        data(){
            return{
                xPosition: 0,
                scrollerID: `groupSessionScroller${uuidv4()}`,
                scroller: null,
            }
        },
        created(){
        },
        mounted(){
            this.scroller = document.querySelector(`#${this.scrollerID}`)
        },
        computed: {
            showRightArrow(){
                if (this.scroller !== null ){
                    if (this.xPosition === 0 && this.scroller.scrollWidth > this.scroller.clientWidth) {
                        return true
                    } else {
                        return this.xPosition < (this.scroller.scrollWidth - this.scroller.clientWidth)
                    }
                } else { return true }
            },
            showLeftArrow(){
                return this.xPosition > 0
            }
        },
        methods: {
            rightArrowPressed(){
                // console.log("scrollWidth", this.scroller.scrollWidth)
                // console.log("clientWidth", this.scroller.clientWidth)
                const maxScroll =  this.scroller.scrollWidth - this.scroller.clientWidth
                let newPosition = this.xPosition + this.scroller.clientWidth
                if (newPosition > maxScroll) {
                    newPosition = maxScroll
                }
                this.scroller.scrollTo({
                    left: newPosition,
                    behavior: "smooth",
                })
            },
            leftArrowPressed(){
                let newPosition = this.xPosition - this.scroller.clientWidth
                if (newPosition < 0) {
                    newPosition = 0
                }
                this.scroller.scrollTo({
                    left: newPosition,
                    behavior: "smooth",
                })
            },
            scrolled(e){
                const position = e.target.scrollLeft.valueOf()
                this.xPosition = position
                // console.log("item was scrolling damnit!", e)
            },
            checkYPosition(){
                let element  = document.querySelector(`#${this.scrollerID}`)
                console.log("Element: ", element)
                this.xPosition = element.scrollLeft.valueOf()
            },
            offset(el){
                let rect = el.getBoundingClientRect(),
                        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft}
            },

    openSession(){
                console.log("Opening Session")
            },
            option1Pressed(){
                console.log("option pressed")
            },
        }
    }
</script>

<style scoped>
    /**:focus {*/
    /*    outline: none;*/
    /*}*/

    .scroller::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .scroller {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
</style>
