<template>

    <div v-if="groupSessions !== null" class="max-w-7xl mx-auto my-10">
        <!--        <div class="bg-gray-200 flex items-center justify-evenly flex-wrap shadow px-4 py-5 sm:rounded-lg sm:p-6">-->
        <!--            <group-session-card v-for="(session) in myGroupSessions.participating" :key="session.JID" @click="openSession" :session="session"></group-session-card>-->
        <!--        </div>-->

        <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->

<!--        <empty-integration-c-t-a :community-owner="communityOwner" :community-name="communityName"></empty-integration-c-t-a>-->


        <div class="bg-indigo-50 rounded-md">
            <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                    <span class="block">Engage in live video discussions.</span>
                    <span class="block text-indigo-600">Share thoughts. Listen to others.</span>
                </h2>
                <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                    <div class="inline-flex rounded-md shadow">
                        <button @click="createNewGroupSession(true)" href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                            Create a session
                        </button>
                    </div>
                </div>
            </div>
        </div>
<!--        <div>-->
<!--            <div v-if="nextSevenDays.length === 0 && moreThanSevenDays.length === 0">-->
<!--                <empty-integration-c-t-a :community-owner="communityOwner" :community-name="communityName"></empty-integration-c-t-a>-->
<!--            </div>-->
<!--            <div v-else>-->

<!--            </div>-->
<!--        </div>-->

        <div class="mt-10">
            <div v-if="now.length > 0" class="mt-5">
                <group-sessions-scroller :my-group-sessions="now">
                    <template v-slot:sectionTitle>
                        Now
                    </template>
                </group-sessions-scroller>
            </div>

            <div v-if="today.length > 0" class="">
                <group-sessions-scroller :my-group-sessions="today">
                    <template v-slot:sectionTitle>
                        Today
                    </template>
                </group-sessions-scroller>
            </div>

            <div v-if="nextSevenDays.length > 0" class="">
                <group-sessions-scroller :my-group-sessions="nextSevenDays">
                    <template v-slot:sectionTitle>
                        Next 7 Days
                    </template>
                </group-sessions-scroller>
            </div>

            <!--        <div class="">-->
            <!--            <group-sessions-scroller :my-group-sessions="allGroupSessions">-->
            <!--                <template v-slot:sectionTitle>-->
            <!--                    From your subscriptions-->
            <!--                </template>-->
            <!--            </group-sessions-scroller>-->
            <!--        </div>-->

            <div v-if="moreThanSevenDays.length > 0" class="">
                <group-sessions-scroller :my-group-sessions="moreThanSevenDays">
                    <template v-slot:sectionTitle>
                        Future sessions
                    </template>
                </group-sessions-scroller>
            </div>

            <div v-if="pastSessions.length > 0" class="">
                <group-sessions-scroller :my-group-sessions="pastSessions">
                    <template v-slot:sectionTitle>
                        Past sessions
                    </template>
                </group-sessions-scroller>
            </div>
        </div>
    </div>


</template>

<script>
    import * as _ from 'lodash'
    import {timeDateFromInput, isToday, isInNextSevenDays, isInAboveSevenDays, isInThePast, isSessionNow} from "@/utils/helpers";
    import * as axios from "@/axios-auth"
    import GroupSessionsScroller from "@/components/GroupSessionsScroller";
    // import EmptyIntegrationCTA from "@/pages/communityPage/components/EmptyIntegrationCTA";
    export default {
        name: "CommunityGroupSessions",
        components: {
            // EmptyIntegrationCTA,
            GroupSessionsScroller
        },
        props: ['communityOwner', 'communityName'],
        data(){
            return {
                groupSessions: null,
            }
        },
        created(){
            this.fetchCommunityGroupSessions()
        },
        computed:{
            now(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isSessionNow(session.groupSession.startsAt)
                })
            },
            today(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isToday(session.groupSession.startsAt)
                })
            },
            nextSevenDays(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isInNextSevenDays(session.groupSession.startsAt)
                })
            },
            moreThanSevenDays(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isInAboveSevenDays(session.groupSession.startsAt)
                })
            },
            pastSessions(){
                return _.filter(this.sortedGroupSessions, (session) => {
                    return isInThePast(session.groupSession.startsAt)
                })
            },
            sortedGroupSessions(){
                return _.orderBy(this.groupSessions, ['groupSession.startsAt'], ['asc'])
                // const ordered = _.orderBy(allGroupSessions, ['groupSession.startsAt'], ['asc'])
                // return _.orderBy(ordered, ['status'], ['desc'])
            }
        },
        methods: {
            isSessionNow,
            timeDateFromInput,
            isToday,
            isInNextSevenDays,
            isInAboveSevenDays,
            isInThePast,
            createNewGroupSession(){
                this.$router.push({name: 'newGroupSession', query: {communityOwner: this.communityOwner, communityName: this.communityName}})
            },
            openSession(){
                console.log("Opening Session")
            },
            option1Pressed(){
                console.log("option pressed")
            },
            fetchCommunityGroupSessions(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.get(`/v2/community/integrations/groupSessions/getAllGroupSessions/${this.communityOwner}/${this.communityName}`)
                .then((resp) => {
                    if(resp.status === 200 || resp.status === 201) {
                        this.groupSessions = resp.data

                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                    loader.hide()
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: searchCommunities FAILED", err)
                })
            }
        },
    }
</script>

<style scoped>

</style>
