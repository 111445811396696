<template>
    <div  @click="goToSession"
         class="flex bg-white flex-col rounded-lg mx-1 mb-10 flex-none w-9/12 sm:w-8/12 md:w-5/12 lg:w-5/12 xl:w-4/12 shadow-lg cursor-pointer">
        <!--                <div class="flex-shrink-0">-->
        <!--                    <img class="h-48 w-full object-cover" :src="httpsLink(course.image_480x270)" alt="" />-->
        <!--                </div>-->
        <div class="h-full flex-grow flex">
            <div class="relative flex-1 px-5 py-5 flex flex-col justify-between">
<!--                <div class="absolute top-3 right-1 inline-block text-left ml-1" style="z-index: 100">-->
<!--                    <div class="">-->
<!--                        <button @click.stop="showOptionsMenu = !showOptionsMenu" class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:text-gray-600" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">-->
<!--                            <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">-->
<!--                                <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />-->
<!--                            </svg>-->
<!--                        </button>-->
<!--                    </div>-->

<!--                    <transition enter-class="transform opacity-0 scale-y-95"-->
<!--                                enter-active-class="transition ease-out duration-100"-->
<!--                                enter-to-class="transform opacity-100 scale-y-100"-->
<!--                                leave-class="transform opacity-100 scale-y-100"-->
<!--                                leave-active-class="transition ease-in duration-75"-->
<!--                                leave-to-class="transform opacity-0 scale-y-95">-->

<!--                        <div v-if="showOptionsMenu" v-on-clickaway="closeOptionsMenu" class="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg">-->
<!--                            <div class="rounded-md bg-gray-100 shadow-xs">-->
<!--                                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">-->



<!--                                    &lt;!&ndash;                                                <button @click="revokeRequest"  class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">Revoke Request</button>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                <button  @click="declineRequest" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">Decline Request</button>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                <button @click="removeCoLearner" class="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-200 focus:text-gray-900" role="menuitem">Remove CoLearner</button>&ndash;&gt;-->

<!--                                    &lt;!&ndash;                                            <button v-if="relationships.coLearner ==='meToYou'" @click="revokeRequest" class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                &lt;!&ndash; Heroicon name: pencil-alt &ndash;&gt;&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"></path>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                </svg>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                Revoke Request&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            </button>&ndash;&gt;-->

<!--                                    &lt;!&ndash;                                            <button v-if="relationships.coLearner ==='youToMe'" @click="declineRequest" class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                &lt;!&ndash; Heroicon name: pencil-alt &ndash;&gt;&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                </svg>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                Decline Invitation&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            </button>&ndash;&gt;-->

<!--                                    &lt;!&ndash;                                            <button v-if="relationships.coLearner ==='coLearners'" @click="removeCoLearnerButtonPressed" class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                &lt;!&ndash; Heroicon name: pencil-alt &ndash;&gt;&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"></path>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                </svg>&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                                Remove CoLearner&ndash;&gt;-->
<!--                                    &lt;!&ndash;                                            </button>&ndash;&gt;-->

<!--                                    <button @click="somethingPressed"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">-->
<!--                                        &lt;!&ndash; Heroicon name: pencil-alt &ndash;&gt;-->
<!--                                        <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
<!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>-->
<!--                                        </svg>-->
<!--                                        Delete-->
<!--                                    </button>-->

<!--                                    <button @click="somethingPressed"  class="group flex w-full items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">-->
<!--                                        &lt;!&ndash; Heroicon name: pencil-alt &ndash;&gt;-->
<!--                                        <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">-->
<!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"></path>-->
<!--                                        </svg>-->
<!--                                        Block User-->
<!--                                    </button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </transition>-->
<!--                </div>-->
                <div class="flex flex-col 3xl:flex-row 3xl:justify-between justify-start items-center">
                    <div class="flex-shrink-0 flex items-center justify-start">
                        <profile-image-with-pop-card size="sm" :prop-user="session.groupSession.initiator" style="z-index: 200"></profile-image-with-pop-card>
                        <div class="text-xs ml-2 leading-5 font-medium text-gray-900">
                            <div class="">
                                {{session.groupSession.initiator.firstName + " " + session.groupSession.initiator.lastName}}
                                <span class="text-gray-500 ml-1">
                                        @{{session.groupSession.initiator.username}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div v-if="session.groupSession.sessionType === 'community' || session.groupSession.sessionType === 'lecture'">
                        <nav  class="sm:flex mr-5" aria-label="Breadcrumb">
                            <ol class="flex items-center">
                                <li>
                                    <div class="flex items-center">
                                        <button @click.stop="goToCommunityOwner" class="text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ communityOwner }}</button>
                                    </div>
                                </li>
                                <li>
                                    <div class="flex items-center">
                                        <!-- Heroicon name: chevron-right -->
                                        <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                        </svg>
                                        <button @click.stop="goToCommunityGroupSessions" class=" text-sm font-medium text-indigo-600 hover:text-indigo-800">{{ communityName }}</button>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div class="flex-1 flex justify-between mt-3 h-48">
                    <div class="flex flex-col w-full">
                        <div class="flex items-start justify-between">
                            <div class="w-3/4 break-normal">
                                <p class="text-xl leading-7 font-semibold text-gray-900 break-normal">
                                    {{ session.groupSession.topic }}
                                </p>
                            </div>
                            <div class="ml-2 mt-1 flex justify-end items-start w-1/4">
                                <div class="flex items-center">
                                    <span v-if="session.status === 'attending'" class="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4 font-medium bg-teal-100 rounded-full">Attending</span>
                                    <span v-if="session.status === 'invited'" class="flex-shrink-0 inline-block px-2 py-0.5 text-white text-xs leading-4 font-medium bg-jiruto-flatRed rounded-full">Invited</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-grow overflow-y-scroll">
                            <p class="mt-1 text-xs leading-2 text-gray-600" style="white-space: pre-line">
                                {{ session.groupSession.agenda }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="mt-6 flex items-center justify-between">
                    <div class="flex items-center">
                        <div class="">

                            <div class="flex">
                                <div class="flex items-center text-xs leading-5 text-gray-500">
                                    <!-- Heroicon name: calendar -->
                                    <svg class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                                    </svg>
                                    <span>
<!--                                        {{session.groupSession.startsAt}}-->
<!--                                        <br>-->
                                        <time datetime="2020-01-07">{{timeDateFromInput(session.groupSession.startsAt)}}</time>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
<!--                        {{participantsAndInvitees}}-->
                        <div class="mt-4 flex-shrink-0 sm:mt-0 cursor-pointer">
                            <div class="flex overflow-hidden">
<!--                                :key="participant.user.JID"-->
                                <img  v-for="(participant, index) in participantsAndInvitees" :key="index"
                                      class="inline-block h-6 w-6 rounded-full text-white shadow-solid bg-gray-300"
                                      :class="{'-ml-2': index > 0}"
                                      :src="participant.user.avatarUrl" alt="">
                                <div class="inline-block text-center h-6 w-6 rounded-full text-white shadow-solid bg-indigo-500 -ml-2">{{participantsAndInvitees.length}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="session.groupSession.sessionType === 'private' && session.status === 'invited' && !tooLate">
            <div class="border-t border-gray-200">
                <div class="-mt-px flex">
                    <div class="w-0 flex-1 flex border-r border-gray-200">
                        <button @click.stop="attendSession" class="bg-indigo-500 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 hover:bg-indigo-600 active:bg-indigo-700 active:outline-none focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                            <!-- Heroicon name: mail -->
<!--                            <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">-->
<!--                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />-->
<!--                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />-->
<!--                            </svg>-->
                            <span class="text-white">Accept</span>
                        </button>
                    </div>
                    <div class="-ml-px w-0 flex-1 flex">
                            <button @click.stop="declineInvitation"
                                    class="relative w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-900  hover:bg-gray-100 active:bg-gray-200 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                            <span class="">Decline</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="session.groupSession.sessionType === 'community' && session.status !== 'attending' && !tooLate && isSubscribed">
            <div class="border-t border-gray-200">
                <div class="-mt-px flex">
                    <div class="-ml-px w-0 flex-1 flex">
                        <button @click.stop="attendCommunitySession"
                                class="relative bg-indigo-600 w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5
                                text-white font-medium border border-transparent rounded-b-lg hover:text-white
                                hover:bg-indigo-500 active:bg-indigo-600 focus:outline-none focus:shadow-outline-blue
                                focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                            <span class="">Attend Group Session</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div @click.stop="subscribeAndAttend" v-if="session.groupSession.sessionType === 'community' && session.status !== 'attending' && !tooLate && !isSubscribed">
            <div class="border-t border-gray-200">
                <div class="-mt-px flex">
                    <div class="-ml-px w-0 flex-1 flex">
                        <div
                                class="relative bg-jiruto-flatRed w-0 flex-1 inline-flex items-center justify-center py-2 text-sm leading-5
                                text-white font-medium border border-transparent rounded-b-lg
                                focus:outline-none focus:shadow-outline-blue
                                focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">
                            <span class="">Subscribe & Attend</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapActions} from 'vuex'
    import * as axios from '@/axios-auth'
    import {timeDateFromInput, isToday} from "@/utils/helpers";
    import {directive as onClickaway} from 'vue-clickaway'
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";
    export default {
        name: "GroupSessionCard",
        props: ['session'],
        directives: {
            onClickaway
        },
        components: {
            ProfileImageWithPopCard,
        },
        data(){
            return {
                showOptionsMenu: false,
            }
        },
        computed: {
            communityDetails: {
                get: function() { return this.$store.state.community.communityDetails },
                set: function(newValue) { this.$store.commit("setCommunityDetails", newValue)}
            },
            isSubscribed: {
                get: function() { return this.$store.state.community.isSubscribed },
                set: function(newValue) { this.$store.commit("setIsSubscribed", newValue)}
            },
            communityOwner() {
                if(this.session.groupSession.community !== null){
                    return this.session.groupSession.community.owner.username
                } else {
                    return ""
                }
            },
            communityName() {
                if(this.session.groupSession.community !== null){
                    return this.session.groupSession.community.name
                } else {
                    return ""
                }
            },
            participantsAndInvitees(){
                // let participants = this.session.groupSession.participants
                // return _.filter(this.allGroupSessions, (session) => {
                //     return isInAboveSevenDays(session.groupSession.startsAt)
                // })
                if(this.session.groupSession.invitees === null && this.session.groupSession.attendees === null){
                    return []
                }
                if(this.session.groupSession.invitees === null){
                    return this.session.groupSession.attendees
                }
                if(this.session.groupSession.attendees === null){
                    return this.session.groupSession.invitees
                }

                return this.session.groupSession.attendees.concat(this.session.groupSession.invitees)
            },
            tooLate(){
                const date = new Date(this.session.groupSession.startsAt)
                const now = new Date()

                return now.getTime() - date.getTime() >= 7200000
            },
            communityNameForUrl(){
                return this.communityName.replace(/\s/g, "-")
            },
        },
        methods: {
            ...mapActions(['subscribe']),
            timeDateFromInput,
            isToday,
            goToCommunityGroupSessions(){
                this.$router.push(`/${this.communityOwner}/${this.communityNameForUrl}/groupSessions`)
            },
            goToCommunityOwner(){
              this.$router.push(`/${this.communityOwner}`)
            },
            subscribeAndAttend(){
                this.subscribe()
                .then(() => {
                    this.attendCommunitySession()
                })
                .catch((err) => {
                    console.error("ERROR: subscribeAndAttend FAILED ", err)
                })
            },
            attendCommunitySession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                console.log(`/v2/community/${this.communityOwner}/${this.communityNameForUrl}/groupSessions/${this.session.groupSession.JID}/attend`)
                axios.jigo.put(`/v2/community/${this.communityOwner}/${this.communityNameForUrl}/groupSessions/${this.session.groupSession.JID}/attend`)
                .then((resp) => {
                    if(resp.status === 200) {
                        console.log("attending community session:", resp.data)
                        // this.$router.push(`/${this.communityOwner}/${this.communityName}/groupSessions/${this.session.groupSession.JID}`)
                        this.$router.push(`/groupSession/${this.session.groupSession.JID}`)
                        loader.hide()
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                        loader.hide()
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: attendSession FAILED", err)
                })
            },
            attendSession(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.put(`/v2/groupSession/${this.session.groupSession.JID}/attend`)
                .then((resp) => {
                    if(resp.status === 200) {
                        console.log("attending:", resp.data)
                        this.$router.push(`/groupSession/${this.session.groupSession.JID}`)
                        loader.hide()
                    } else {
                        loader.hide()
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: attendSession FAILED", err)
                })
            },
            declineInvitation(){
                axios.jigo.put(`/v2/groupSession/${this.session.groupSession.JID}/decline`)
                .then((resp) => {
                    if(resp.status === 200) {
                        this.$router.push("/my/groupSessions")
                    } else {
                        console.error("ERROR: unwanted status returned", resp.status)
                    }
                })
                .catch((err) => {
                    console.error("ERROR: declineSession FAILED", err)
                })
            },
            goToSession(){
                this.$router.push(`/groupSession/${this.session.groupSession.JID}`)

                // console.log("Going to session")
            },
            closeOptionsMenu(){
                this.showOptionsMenu = false
            },
            somethingPressed(){
                console.log("Something Pressed")
            }
        },
    }
</script>

<style scoped>
    button:focus{
        outline: 0;
    }
</style>
